<template>
    <div class="app-container button-bottom">
        <div class="transparent-background" :class="showTransparentBackground ? 'show': ''">
            <header>
                <router-link :to="{name: 'Home'}">
                    <button v-show="!searchActive" class="btn-unstyled btn-back">
                        <i class="fas fa-chevron-left"></i>
                    </button>
                </router-link>
                <h2 v-show="!searchActive" :class="(!searchActive && searchButtonClicked) ? 'fadeInDownSlow' : ''">
                    {{turnierStatus === 'active' ?  lang.listTurniere.headlinePrefixCurrent : lang.listTurniere.headlinePrefixPrevious}} {{lang.listTurniere.headline}}
                </h2>
                <button v-if="isOnline" v-show="searchActive" class="btn-unstyled btn-search" @click="search()">
                    <i class="fas fa-search"></i>
                </button>
                <transition
                    v-if="isOnline"
                    enter-active-class="fadeInRight"
                    leave-active-class="fadeOutRight">
                    <input ref="search" v-on:keyup.enter="search" class="search-input search" v-model.trim="searchWord" v-show="searchActive" :placeholder="lang.listTurniere.searchInputPlacholder" type="text" :name="lang.listTurniere.searchInputLabel" id="suche">
                </transition>
                <button v-if="isOnline" class="btn-unstyled btn-open-search" @click="openSearch()">
                    <i v-show="!searchActive" class="fas fa-search"></i>
                    <i v-show="searchActive" class="fas fa-times"></i>
                </button>
                <md-menu v-if="isOnline" :mdCloseOnSelect="true" md-size="small" md-align-trigger :md-offset-x="-184" :md-offset-y="10" v-show="!searchActive">
                    <md-button class="md-icon-button" md-menu-trigger>
                        <md-icon>query_builder</md-icon>
                    </md-button>
                    <md-menu-content>
                        <md-menu-item @click="turnierStatus='closed'">
                            <span :class="turnierStatus === 'closed' ? 'font-color-secondary': ''">{{lang.listTurniere.filterVergangeneTurnier}}</span>
                            <md-icon :class="turnierStatus === 'closed' ? 'font-color-secondary': ''">history</md-icon>
                        </md-menu-item>
                        <md-menu-item @click="turnierStatus='active'">
                            <span :class="turnierStatus === 'active' ? 'font-color-secondary': ''">{{lang.listTurniere.filterAktuelleTurnier}}</span>
                            <md-icon :class="turnierStatus === 'active' ? 'font-color-secondary': ''">today</md-icon>
                        </md-menu-item>
                    </md-menu-content>
                </md-menu>
                <button v-if="isOnline" class="btn-unstyled btn-city" @click="showDialogChangeCity=true" v-show="!searchActive">
                    <i class="fas fa-map-marker-alt"></i>
                </button>
            </header>
        </div>
        <div class="container">
            <div v-show="turniere.length == 0 && searchActive && isDataLoaded">
                <md-empty-state
                    md-icon="search_off"
                    :md-label="lang.listTurniere.emptyStateLabelSearch"
                    :md-description="lang.listTurniere.emptyStateDescriptionSearch">
                </md-empty-state>
            </div>
            <div v-show="turniere.length == 0 && !searchActive && isDataLoaded && turnierStatus === 'closed'">
                <md-empty-state
                    md-icon="emoji_events"
                    :md-label="lang.listTurniere.emptyStateLabelInactive"
                    :md-description="lang.listTurniere.emptyStateDescriptionInactive">
                </md-empty-state>
            </div>
            <div v-show="turniere.length == 0 && !searchActive && isDataLoaded && turnierStatus === 'active'">
                <md-empty-state
                    md-icon="emoji_events"
                    :md-label="lang.listTurniere.emptyStateLabelActive"
                    :md-description="lang.listTurniere.emptyStateDescriptionActive">
                </md-empty-state>
            </div>
            <transition appear name="fadeRight" mode="out-in">
                <div v-show="turniere.length != 0" id="turniere">
                        <ListTurniereComponent 
                            :turniereArray="turnierFilterByStatus" 
                            :showAnzTeams="true"
                            routerLinkTo="TurnierDetail"/>
                </div>
            </transition>
         </div>
        <md-dialog :md-active.sync="showDialogChangeCity" :md-fullscreen=false>
            <div class="dialog-content">
                <p class="headline p-unstyled">{{lang.listTurniere.changeCityFormHeadline}}</p>
                <p class="message p-unstyled">{{lang.listTurniere.changeCityFormMessage}}</p>
                <form @submit.prevent="changeCity" id="changeCityForm">
                    <md-field class="md-field-icon" :class="getValidationClass('stadt')">
                        <i class="fas fa-globe-americas"></i>
                        <label>{{lang.listTurniere.filterLabelStadt}}</label>
                        <md-input @blur="$v.stadt.$touch()" :name="lang.listTurniere.filterLabelStadt" id="stadt" v-model.trim="stadt" type="text"></md-input>
                        <md-icon v-if="isFieldInvalid('stadt')" class="color-invalid">close</md-icon>
                        <span class="md-error" v-if="!$v.stadt.required">{{lang.listTurniere.stadtRequired}}</span>
                    </md-field>
                    <md-dialog-actions>
                        <md-button @click="cancelCityDialog()">{{lang.listTurniere.buttonTextCancel}}</md-button>
                        <md-button type="submit" class="md-primary">{{lang.listTurniere.buttonTextAgree}}</md-button>
                    </md-dialog-actions>
                </form>
            </div>
        </md-dialog>
        <router-link v-if="isOnline" :to="{name: 'CreateTurnier'}" class="add-link">
            <md-button class="md-icon-button md-raised md-primary md-filled md-fab">
                <md-icon>add</md-icon>
            </md-button>
        </router-link>
        <BottomBarDefault/>
        <md-progress-bar v-if="loadingStatus" md-mode="query"></md-progress-bar>
    </div>
</template>

<script>
import store from '@/store/index'
import ListTurniereComponent from '@/components/Turnier/ListTurniereComponent.vue'
import BottomBarDefault from '@/components/Utils/BottomBarDefault.vue'
import {required} from 'vuelidate/lib/validators'
import { VueOfflineMixin } from 'vue-offline'

export default {
    name: 'CreateTurnier',
    mixins: [VueOfflineMixin],
    components: {
        ListTurniereComponent,
        BottomBarDefault,
    },
    data() {
        return {
            searchActive: false,
            searchWord: '',
            showTransparentBackground: false,
            turnierStatus: 'active',
            searchButtonClicked: false,
            showDialogChangeCity: false,
            stadt: '',
            isStadtEmpty: true,
            activePage: 0
        }
    },
    validations: {
        stadt: {
            required
        }
    },
    created() {
        store.commit("SET_TURNIERE_ARRAY", []);
        const stadt = this.getLocalStorageVariable('stadt');
        const turnierStatus = this.getLocalStorageVariable('turnierStatus')
        this.turnierStatus = turnierStatus == "" ? 'active': turnierStatus;
        if (stadt == "") {
            this.showDialogChangeCity = true;
        } else {
            this.isStadtEmpty = false;
            this.stadt = stadt;
            store.dispatch('getTurniere', {stadt: this.stadt, turnierStatus: this.turnierStatus, page: 0, searchWord: this.searchWord});
        }
    },
    mounted() {
        window.addEventListener('scroll', this.getScrollEventListener);
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.getScrollEventListener);
    },
    watch: {
        turnierStatus(newTurnierStatus) {
            if (this.getLocalStorageVariable("turnierStatus") != newTurnierStatus) {
                this.setLocalStorageVariable("turnierStatus", newTurnierStatus);
                this.resetVariablesAndTurnierData();
            }
        },
    },
    methods: {
        getScrollEventListener(e) {
            let bottomOfWindow = document.scrollingElement.scrollTop + window.innerHeight >= document.scrollingElement.offsetHeight;
            if (bottomOfWindow) {
                if (this.stadt != "" && this.turnierStatus != "" && !this.isLastTurnierDataFetchedEmpty && !this.loadingStatus) {
                    this.activePage += 1;
                    store.dispatch('getTurniere', {stadt: this.stadt, turnierStatus: this.turnierStatus, page: this.activePage, searchWord: this.searchWord});
                }
            }
        },
        getValidationClass(fieldName) {
            return {
                'md-invalid': this.isFieldInvalid(fieldName)
            }
        },
        changeCity() {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                this.setLocalStorageVariable("stadt", this.stadt);
                this.showDialogChangeCity=false;
                this.isStadtEmpty = false;
                this.resetVariablesAndTurnierData();
            }
        },
        isFieldInvalid(fieldName) {
            const field = this.$v[fieldName]
            if (field) {
                return field.$invalid && field.$dirty;
            }
        },
        openSearch() { 
            this.searchButtonClicked = true;
            this.searchActive = this.searchActive ? false: true;
            if (!this.searchActive) {
                this.searchWord = '';
                this.resetVariablesAndTurnierData();
            } else {
                this.$nextTick(() => this.$refs.search.focus())
            }
        },
        search() {
            this.resetVariablesAndTurnierData();
        },
        resetVariablesAndTurnierData() {
            store.commit("SET_TURNIERE_ARRAY", []);
            this.activePage = 0;
            store.dispatch('getTurniere', {stadt: this.stadt, turnierStatus: this.turnierStatus, page: 0, searchWord: this.searchWord});
        },
        setLocalStorageVariable(localStorageVariableName, data) {
            localStorage.setItem(localStorageVariableName, data);
        },
        getLocalStorageVariable(localStorageVariableName) {
            return localStorage.getItem(localStorageVariableName) ? localStorage.getItem(localStorageVariableName) : '';
        },
        cancelCityDialog() {
            this.showDialogChangeCity=false;
            this.stadt = this.getLocalStorageVariable('stadt');
        }
    },
    computed: {
        lang() {
            return store.getters.getLanguage;
        },
        loadingStatus() {
            return store.state.turnier.loadingStatus;
        },
        turniere() {
            return store.getters.getTurniere;
        },
        turnierFilterByStatus() {
            if (!this.isStadtEmpty) {
                return this.turniere;
            } else {
                return [];
            }
        },
        isDataLoaded() {
            return store.state.turnier.isDataLoaded;
        },
        isLastTurnierDataFetchedEmpty() {
            return store.getters.isLastTurnierDataFetchedEmpty;
        },
    },
}
</script>

<style scoped lang="scss">
@import '../../assets/scss/base';
.header {
  z-index: 20;

}

.md-menu {
    position: absolute;
    right: 46px;

    .md-icon-button {
        i {
            color: $color-white !important;
        }
    }
}

.btn-city {
    right: 90px;
    position: absolute;
    height: 60px;
    width: 30px;
    i {
        font-size: 1.8rem;
    }
}

.md-button:not([disabled]).md-focused:before,
.md-button:not([disabled]):active:before,
.md-button:not([disabled]):hover:before {
    background-color: transparent;
}

.dialog-content {
    padding: 30px 20px 10px 20px;
    .message {
        font-size: 1.5rem;
        margin-bottom: 15px;
    }
    .md-field {
        margin: 25px 0 30px;
    }
}
</style>
